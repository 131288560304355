import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { AuthSelectors } from '..';

@Injectable()
export class SupportAgentGuard {
  constructor(private store: Store<AppState>) {}

  hasSupportAgentRole(): Observable<boolean> {
    return this.store.select(AuthSelectors.isInitializedSelector).pipe(
      filter(isInitialized => isInitialized),
      switchMap(_ => this.store.select(AuthSelectors.hasSupportAgentRoleSelector)),
      first(),
    );
  }
}
