import { Action, createReducer, on } from '@ngrx/store';
import { ExternalFee } from 'src/app/shared/models/external-fees.model';
import { FirestoreActions } from '.';
import { DispatchTag } from '../../shared/models/dispatch-tag.model';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { VehicleType } from '../../shared/models/vehicle.model';

export interface FirestoreState {
  dispatchTags: DispatchTag[];
  vehicleTypes: VehicleType[];
  productFeatures: ProductFeature[];
  externalFees: ExternalFee[];
}

export const initialState: FirestoreState = {
  dispatchTags: [],
  vehicleTypes: [],
  productFeatures: [],
  externalFees: [],
};

export const supportAgentFirestoreReducer = createReducer(
  initialState,
  on(FirestoreActions.dispatchTagChanged, (state, action) => ({
    ...state,
    dispatchTags: action.tags,
  })),
  on(FirestoreActions.vehicleTypesChanged, (state, action) => ({
    ...state,
    vehicleTypes: action.vehicleTypes,
  })),
  on(FirestoreActions.productFeaturesChanged, (state, action) => ({
    ...state,
    productFeatures: action.features,
  })),
  on(FirestoreActions.externalFeesChanged, (state, action) => ({
    ...state,
    externalFees: action.externalFees,
  })),
);

export function reducer(state: FirestoreState, action: Action) {
  return supportAgentFirestoreReducer(state, action);
}
